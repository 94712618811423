import React, { useEffect, useState } from "react";
import { ADMIN_CONFIG, SCHOOL_CONFIG } from "./AdminConfig";
import CustomHeader from "../../components/CustomHeader";
import { Layout, Menu } from "antd";
import { USER_TYPE_ADMIN } from "../../util/Constants";

const { Content, Sider } = Layout;

const AdminHome = () => {
  const [componentKey, setComponentKey] = useState(false);
  const [menuConfig, setMenuConfig] = useState([]);
  const [component, setComponent] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const user = localStorage.getItem("user");

  const onCollapse = () => {
    setCollapsed(!collapsed);
  };

  const onClickMenu = (e) => {
    if (componentKey) {
      setComponentKey(parseInt(e.key, 10));
    }
  };

  useEffect(() => {
    if (componentKey) {
      const adminComponent = menuConfig.find((val) => val.key === componentKey);
      if (adminComponent) {
        setComponent(adminComponent.component);
      }
    }
  }, [componentKey]);

  useEffect(() => {
    if (user) {
      const userType = JSON.parse(user).userType;
      if (userType === USER_TYPE_ADMIN) {
        setMenuConfig(ADMIN_CONFIG);
        setComponentKey(ADMIN_CONFIG[0].key);
      } else {
        setMenuConfig(SCHOOL_CONFIG);
        setComponentKey(SCHOOL_CONFIG[0].key);
      }
    }
  }, [user]);

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <CustomHeader />
      <Layout className="site-layout">
        <Sider
          theme="light"
          collapsible
          collapsed={collapsed}
          onCollapse={onCollapse}
        >
          <Menu defaultSelectedKeys={[1]} mode="inline" onClick={onClickMenu}>
            {menuConfig.map((val) => (
              <Menu.Item key={val.key} icon={val.icon}>
                {val.label}
              </Menu.Item>
            ))}
          </Menu>
        </Sider>
        <Content style={{ margin: 16 }}>{component}</Content>
      </Layout>
    </Layout>
  );
};

export default AdminHome;

import { configureStore } from "@reduxjs/toolkit";
import appReducer from "./reducer/app";
import testReducer from "./reducer/test";

export default configureStore({
  reducer: {
    app: appReducer,
    test: testReducer,
  },
});

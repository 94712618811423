import React, { useEffect, useState } from "react";
import HomeView from "./HomeView";
import "./style.css";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setTestList } from "../../reducer/test";

const Home = (props) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const test = useSelector((state) => state.test);
  const app = useSelector((state) => state.app);

  useEffect(() => {
    if (test.list.length === 0 && app.user) {
      setIsLoading(true);
      axios
        .get("/api/test/list")
        .then((response) => {
          if (response.data.success) {
            dispatch(setTestList(response.data.data));
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, []);

  return <HomeView isLoading={isLoading} test={test} />;
};

export default Home;

import React, { useEffect, useState } from "react";
import { Button, Card, Divider, Flex, Select, Space, Table, Tabs } from "antd";
import axios from "axios";
import Text from "antd/es/typography/Text";
import {
  generateStringFilterFunc,
  getFilterValues,
  handleDownload,
} from "../../../util/HelperFuncs";
import {
  SELECTION_INVERT,
  SELECTION_NONE,
} from "antd/es/table/hooks/useSelection";
import { DownloadOutlined } from "@ant-design/icons";

const TestReports = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [userTest, setUserTest] = useState([]);
  const [stuList, setStuList] = useState([]);
  const [bulkResultColumns, setBulkResultColumns] = useState([]);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [infoText, setInfoText] = useState("");
  const [testList, setTestList] = useState([]);
  const [selectedTest, setSelectedTest] = useState(null);

  const onClickReport = (data) => {
    axios
      .get(`/api/admin/getReportLink?testId=${data.id}&userId=${selectedUser}`)
      .then((response) => {
        if (response.data.success) {
          window.open(response.data.data, "_blank");
        }
      });
  };

  const onTestChanged = (value) => {
    setSelectedTest(value);

    setIsLoading(true);
    axios
      .get("/api/admin/getTestUserList?testId=" + value)
      .then((response) => {
        if (response.data.success) {
          console.log(response.data.data);
          bulkColumns[5].filters = getFilterValues(
            response.data.data,
            "schoolName"
          );
          setBulkResultColumns(bulkColumns);
          setStuList(response.data.data);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onDownloadReportZip = () => {
    setIsLoading(true);
    handleDownload(
      "/api/admin/getBulkReportZip",
      { testId: selectedTest, userIds: selectedRowIds },
      "PDR Rapor.zip",
      () => {
        setIsLoading(false);
      }
    );
  };

  const columns = [
    {
      title: "Test",
      dataIndex: "testName",
    },
    {
      title: "Raporla",
      dataIndex: "id",
      render: (text, record) => (
        <Button type="primary" onClick={() => onClickReport(record)}>
          Raporla
        </Button>
      ),
    },
  ];

  const bulkColumns = [
    {
      title: "Ad",
      dataIndex: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Soyad",
      dataIndex: "surName",
      sorter: (a, b) => a.surName.localeCompare(b.surName),
    },
    {
      title: "Sınıf Seviyesi",
      dataIndex: "classLevel",
    },
    {
      title: "Cinsiyet",
      dataIndex: "gender",
      filters: [
        { text: "Erkek", value: "E" },
        { text: "Kadın", value: "K" },
      ],
      onFilter: (value, record) => record.gender.indexOf(value) === 0,
    },
    {
      title: "Telefon",
      dataIndex: "phone",
    },
    {
      dataIndex: "schoolName",
      title: "Okul",
      onFilter: generateStringFilterFunc("schoolName"),
      filterSearch: true,
      sorter: (a, b) => a.schoolName.localeCompare(b.schoolName),
    },
    {
      title: "Email",
      dataIndex: "email",
    },
  ];

  useEffect(() => {
    setIsLoading(true);
    axios
      .get("/api/admin/userList")
      .then((response) => {
        if (response.data.success) {
          setOptions(
            response.data.data.map((val) => ({
              value: val.id,
              label: `${val.name} ${val.surName}`,
            }))
          );
        }
      })
      .finally(() => {
        setIsLoading(false);
      });

    axios
      .get("/api/admin/getTestList")
      .then((response) => {
        if (response.data.success) {
          setTestList(response.data.data);
          setTestList(
            response.data.data.map((val) => ({
              value: val.id,
              label: `${val.testName}`,
            }))
          );
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    if (selectedUser) {
      setIsLoading(true);
      axios
        .get(`/api/admin/studentTestList?userId=${selectedUser}`)
        .then((response) => {
          if (response.data.success) {
            setUserTest(response.data.data);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [selectedUser]);

  const stuResults = (
    <Card>
      <Select
        disabled={isLoading}
        showSearch
        value={selectedUser}
        onChange={setSelectedUser}
        style={{ width: 300 }}
        placeholder="Öğrenci Seçiniz"
        optionFilterProp="children"
        options={options}
        filterOption={(input, option) =>
          option.label.toLocaleLowerCase().indexOf(input.toLocaleLowerCase()) >=
          0
        }
      />
      <Divider />
      {userTest.length === 0 ? (
        <Text>Kullanıcının cevaplamış olduğu test bulunmamaktadır.</Text>
      ) : (
        <Table scroll={{ x: 400 }} dataSource={userTest} columns={columns} />
      )}
    </Card>
  );

  const onDownloadClick = () => {
    handleDownload(
      "/api/admin/getBulkReportLink",
      { testId: 1, userIds: selectedUser },
      "raporlar.zip"
    );
  };

  const footerRender = () => {
    return (
      <Flex justify={"space-between"}>
        <Text type={selectedRowIds.length > 20 ? "danger" : "success"}>
          Seçilen Öğrenci Sayısı: {selectedRowIds.length}
        </Text>
        <Text type="secondary">{infoText}</Text>
        <Button
          type="primary"
          disabled={isLoading || selectedRowIds.length === 0 || selectedRowIds.length > 20}
          onClick={onDownloadReportZip}
          icon={<DownloadOutlined />}
        >
          Raporları Oluştur
        </Button>
      </Flex>
    );
  };

  const bulkResult = (
    <Card>
      <Space>
        <Text>Test Adı: </Text>
        <Select
          disabled={isLoading}
          showSearch
          value={selectedTest}
          onChange={onTestChanged}
          style={{ width: 300 }}
          placeholder="Test Seçiniz"
          optionFilterProp="children"
          options={testList}
          filterOption={(input, option) =>
            option.label
              .toLocaleLowerCase()
              .indexOf(input.toLocaleLowerCase()) >= 0
          }
        />
      </Space>
      <Divider />
      <Table
        loading={isLoading}
        rowSelection={{
          selections: [
            Table.SELECTION_ALL,
            Table.SELECTION_INVERT,
            Table.SELECTION_NONE,
          ],
          onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowIds(selectedRowKeys);
            if (selectedRowKeys.length > 20) {
              setInfoText("En fazla 20 öğrenci seçilebilir.");
            } else {
              setInfoText("");
            }
          },
        }}
        bordered
        scroll={{ x: 400 }}
        columns={bulkResultColumns}
        rowKey={(user) => user.id}
        dataSource={stuList}
        loading={isLoading}
        footer={footerRender}
      />
    </Card>
  );

  return (
    <Tabs
      defaultActiveKey="1"
      items={[
        {
          label: "Öğrenciye Göre Test Sonuçları",
          key: 1,
          children: stuResults,
        },
        {
          label: "Teste Göre Toplu Sonuçlar",
          key: 2,
          children: bulkResult,
        },
      ]}
    />
  );
};

export default TestReports;

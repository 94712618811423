import React, { useEffect, useState } from "react";
import {
  Card,
  Radio,
  Layout,
  Space,
  Button,
  Spin,
  Modal,
  Checkbox,
  Typography, Divider
} from "antd";
import CustomHeader from "../../components/CustomHeader";
import { useHistory } from "react-router-dom";
import Text from "antd/es/typography/Text";

const { Content } = Layout;

const TestView = ({
  title,
  questions,
  onSubmitAnswers,
  isLoading,
  showHasNoQuestionModal,
  solveAgainClick,
  showResultClick,
  isUserSolveBefore,
  isSavingResult,
  showResultModal,
  showResultModalClick,
}) => {
  const [answers, setAnswers] = useState([]);
  const history = useHistory();

  const onClickQuestion = (questionNo, e) => {
    setAnswers(
      answers.map((val) => {
        if (val.questionNo === questionNo) {
          return { ...val, value: e.target.value };
        }
        return val;
      })
    );
  };

  const onClickMultipleQuestion = (questionNo, e, index) => {
    setAnswers(
      answers.map((val) => {
        if (val.questionNo === questionNo) {
          const answer = val.value
            .split("")
            .map((option, i) => {
              if (i === index) {
                return e.target.checked ? e.target.value : " ";
              }
              return option;
            })
            .join("");
          console.log(answer);
          return { ...val, value: answer };
        }
        return val;
      })
    );
  };

  const QuestionValue = (questionNo) => {
    return answers.find((val) => val.questionNo === questionNo)
      ? answers.find((val) => val.questionNo === questionNo).value
      : null;
  };

  const onClickSendAnswer = () => {
    onSubmitAnswers(answers);
  };

  useEffect(() => {
    setAnswers(
      questions.map((val) => {
        if (val.selectionType === 1) {
          return {
            questionNo: val.questionNo,
            value: " ".repeat(val.options.length),
          };
        }
        return { questionNo: val.questionNo, value: null };
      })
    );
  }, [questions]);

  return (
    <>
      <Layout>
        <CustomHeader title={title} canGoHome />
        <Content>
          {isLoading && <Spin size="large" className="loader" />}
          {!isUserSolveBefore && (
            <div style={{ maxWidth: 768, margin: "auto" }}>
              {questions.map((question) => (
                <Card
                  headStyle={{
                    whiteSpace: "normal",
                  }}
                  style={{ margin: 16 }}
                  key={question.questionNo.toString()}
                >
                  <Typography.Title level={5}>
                    {`${question.questionNo} - ${question.question}`}
                  </Typography.Title>
                  <Divider />
                  {question.selectionType === 0 && (
                    <Radio.Group
                      onChange={(e) => onClickQuestion(question.questionNo, e)}
                      value={QuestionValue(question.questionNo)}
                    >
                      <Space direction="vertical">
                        {question.options.map((option) => (
                          <Radio
                            key={option.result}
                            value={option.result}
                            disabled={isSavingResult}
                          >
                            {option.option}
                          </Radio>
                        ))}
                      </Space>
                    </Radio.Group>
                  )}

                  {question.selectionType === 1 && (
                    <Space direction="vertical">
                      {question.options.map((option, index) => (
                        <Checkbox
                          value={option.result}
                          onChange={(e) =>
                            onClickMultipleQuestion(
                              question.questionNo,
                              e,
                              index
                            )
                          }
                          key={index.toString()}
                          disabled={isSavingResult}
                        >
                          {option.option}
                        </Checkbox>
                      ))}
                    </Space>
                  )}
                </Card>
              ))}
              {questions.length > 0 && (
                <Button
                  disabled={isSavingResult}
                  loading={isSavingResult}
                  onClick={onClickSendAnswer}
                  type="primary"
                  style={{ marginLeft: 16, marginBottom: 16 }}
                >
                  Gönder
                </Button>
              )}
            </div>
          )}

          {isUserSolveBefore && (
            <div
              style={{
                maxWidth: 768,
                margin: "auto",
                display: "flex",
                flexDirection: "column",
                padding: 24,
              }}
            >
              <h3 style={{ textAlign: "center" }}>
                <b>{title}</b> anketini daha önceden çözmüşsünüz.
              </h3>
              <div
                style={{
                  display: "flex",
                  padding: 8,
                  justifyContent: "space-around",
                }}
              >
                <Button type="success" onClick={solveAgainClick}>
                  Tekrar Çöz
                </Button>
                <Button type="primary" onClick={showResultClick}>
                  Raporu görüntüle
                </Button>
              </div>
            </div>
          )}
        </Content>
      </Layout>
      <Modal
        width={768}
        title="Dikkat !"
        visible={showHasNoQuestionModal}
        closable={false}
        footer={[
          <Button
            type="primary"
            onClick={() => {
              history.replace("/ana-sayfa");
            }}
          >
            Ana Sayfa
          </Button>,
        ]}
      >
        <p>Bu ankete soru girilmemiş.</p>
      </Modal>
      <Modal
        width={768}
        title="Anket kaydedildi."
        visible={showResultModal}
        closable={false}
        footer={[
          <Button
            type="primary"
            onClick={() => {
              history.replace("/ana-sayfa");
            }}
          >
            Ana sayfaya git
          </Button>,
          <Button type="success" onClick={showResultModalClick}>
            Görüntüle
          </Button>,
        ]}
      >
        <p>Anket sonucunu görüntülemek istiyor musunuz.</p>
      </Modal>
    </>
  );
};

export default TestView;

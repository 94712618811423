import React, { useEffect, useState } from "react";
import { Button, Card, Table } from "antd";
import axios from "axios";
import CsvDownloader from "react-csv-downloader";
import { generateStringFilterFunc, getFilterValues } from "../../../util/HelperFuncs";

const columns = [
  {
    title: "Ad",
    dataIndex: "name",
  },
  {
    title: "Soyad",
    dataIndex: "surName",
  },
  {
    title: "Sınıf Seviyesi",
    dataIndex: "classLevel",
  },
  {
    title: "Cinsiyet",
    dataIndex: "gender",
    filters: [
      { text: "Erkek", value: "E" },
      { text: "Kadın", value: "K" },
    ],
    onFilter: (value, record) => record.gender.indexOf(value) === 0,
  },
  {
    title: "Telefon",
    dataIndex: "phone",
  },
  {
    dataIndex: "schoolName",
    title: "Okul",
    onFilter: generateStringFilterFunc("schoolName"),
    filterSearch: true,
  },
  {
    title: "Email",
    dataIndex: "email",
  },
  {
    title: "Kayıt Tarihi",
    dataIndex: "createDate",
    sorter: (a, b) => new Date(a.createDate) - new Date(b.createDate),
  },
];

const header = [
  {
    id: "name",
    displayName: "Ad",
  },
  {
    id: "surName",
    displayName: "Soyad",
  },
  {
    id: "classLevel",
    displayName: "Sınıf Seviyesi",
  },
  {
    id: "gender",
    displayName: "Cinsiyet",
  },
  {
    id: "phone",
    displayName: "Telefon",
  },
  {
    id: "school",
    displayName: "Okul",
  },
  {
    id: "email",
    displayName: "Email",
  },
  {
    id: "createDate",
    displayName: "Kayıt Tarihi",
  },
];

const StudentList = (props) => {
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get("/api/admin/userList")
      .then((response) => {
        if (response.data.success) {
          setUserList(response.data.data);
          columns[5].filters = getFilterValues(
            response.data.data,
            "schoolName"
          );
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <Card title="Öğrenci Listesi">
      {userList.length > 0 && (
        <CsvDownloader
          style={{ display: "inline-block", marginBottom: 16 }}
          datas={userList}
          filename="ogrenci-listesi"
          columns={header}
          separator=";"
        >
          <Button type="primary">Excele aktar</Button>
        </CsvDownloader>
      )}
      <Table
        bordered
        scroll={{ x: 400 }}
        columns={columns}
        rowKey={(user) => user.id}
        dataSource={userList}
        loading={isLoading}
      />
    </Card>
  );
};

export default StudentList;

import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Home from "./Home";
import Login from "./Login";
import Test from "./Test";
import NotFount404 from "./NotFount404";
import { AdminLogin, AdminHome } from "./Admin";
import routes from "../util/Routes";

const App = () => {
  return (
    <BrowserRouter>
      <Switch>
        {routes.map((val) => (
          <Route key={val.path} {...val} />
        ))}
      </Switch>
    </BrowserRouter>
  );
};

export { Home, Login, Test, App, NotFount404, AdminHome, AdminLogin };

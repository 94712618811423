import React from "react";
import { Layout, Spin } from "antd";

const LoadingPage = (props) => (
  <Layout style={{ height: "100vh" }}>
    <Spin
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
      }}
      size="large"
    />
  </Layout>
);

export default LoadingPage;

import React from "react";
import { Carousel, Layout, Spin } from "antd";
import CustomHeader from "../../components/CustomHeader";
import { Link } from "react-router-dom";
import slugify from "slugify";

const { Content } = Layout;

const HomeView = ({ isLoading, test }) => {
  return (
    <Layout>
      <CustomHeader id="header">
        <span style={{ color: "white" }}>Test</span>
      </CustomHeader>
      <Content>
        <Carousel autoplay dotPosition="left">
          <div className="carousel-image-content">
            <img src={process.env.PUBLIC_URL + "/assets/images/galeri-1.jpg"} />
          </div>
          <div className="carousel-image-content">
            <img src={process.env.PUBLIC_URL + "/assets/images/galeri-2.jpg"} />
          </div>
          <div className="carousel-image-content">
            <img src={process.env.PUBLIC_URL + "/assets/images/galeri-3.jpg"} />
          </div>
        </Carousel>
        {isLoading && <Spin size="large" className="loader" />}
      </Content>
      <div id="container">
        <div id="menu">
          {test.list.map((val) => (
            <Link
              key={val.id.toString()}
              className="menu-item"
              to={{
                pathname: `/test/${slugify(val.testName, {
                  lower: true,
                  locale: "en",
                })}`,
                state: { id: val.id, title: val.testName },
              }}
            >
              <span>{val.testName}</span>
            </Link>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default HomeView;

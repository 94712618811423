import React, { useEffect, useState } from "react";
import { Button, Card, Form, Input, Modal, Table, Tag } from "antd";
import axios from "axios";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const styles = {
  changePassword: {
    padding: 8,
    fontSize: 14,
    cursor: "pointer",
  },
  deleteSchool: {
    padding: 8,
    fontSize: 14,
    cursor: "pointer",
  },
};

const columns = (onClickChangeEdit, onClickChangeDelete) => [
  {
    title: "Okul İsmi",
    dataIndex: "schoolName",
  },
  {
    title: "Kullanıcı Adı",
    dataIndex: "username",
  },
  {
    title: "Şifre",
    dataIndex: "password",
  },
  {
    title: "Seçenekler",
    dataIndex: "options",
    render: (text, record) => (
      <>
        <Tag
          color="blue"
          style={styles.changePassword}
          onClick={() => onClickChangeEdit(record)}
        >
          Düzenle
        </Tag>
        <Tag
          color="red"
          style={styles.deleteSchool}
          onClick={() => onClickChangeDelete(record)}
        >
          Sil
        </Tag>
      </>
    ),
  },
];

const SchoolSettings = (props) => {
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [schools, setSchools] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    setIsLoading(true);
    axios
      .get("/api/admin/schoolList")
      .then((response) => {
        if (response.data.success) {
          setSchools(response.data.result);
          setIsVisibleModal(false);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const onCancel = () => {
    form.resetFields();
    setIsVisibleModal(false);
  };

  const onOk = () => {
    setIsVisibleModal(true);
    form.submit();
  };

  const onFinish = (values) => {
    setIsLoading(true);
    if (selectedSchool) {
      values.id = selectedSchool.id;
    }
    axios
      .post("/api/admin/createEditSchool", values)
      .then((response) => {
        if (response.data.success) {
          form.resetFields();
          if (values.id) {
            setSchools(
              schools.map((val) => {
                if (val.id === response.data.data.id) {
                  return response.data.data;
                }
                return val;
              })
            );
          } else {
            setSchools([response.data.data, ...schools]);
          }
          setIsVisibleModal(false);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onClickChangeEdit = (record) => {
    setSelectedSchool(record);
    form.setFieldsValue(record);
    setIsVisibleModal(true);
  };
  const onClickChangeDelete = (record) => {
    Modal.confirm({
      title: "Okul silinsin mi ?",
      icon: <ExclamationCircleOutlined />,
      okText: "Evet",
      cancelText: "İptal",
      onOk: () => {
        setIsLoading(true);
        axios
          .post("/api/admin/deleteSchool", { id: record.id })
          .then((response) => {
            if (response.data.success) {
              setSchools(schools.filter((val) => val.id !== record.id));
              setIsVisibleModal(false);
            }
          })
          .finally(() => {
            setIsLoading(false);
          });
      },
    });
  };

  return (
    <>
      <Card title="Okul işlemleri">
        <Button
          type="primary"
          onClick={() => {
            setIsVisibleModal(true);
          }}
        >
          Okul Ekle
        </Button>
        <Table
          columns={columns(onClickChangeEdit, onClickChangeDelete)}
          dataSource={schools}
          loading={isLoading}
          rowKey={(record) => record.id.toString()}
        />
        <Modal
          title="Okul Ekle-Düzenle"
          visible={isVisibleModal}
          onOk={onOk}
          onCancel={onCancel}
          maskClosable={false}
          okText="Kaydet"
          cancelText="İptal"
        >
          <Form layout="vertical" form={form} onFinish={onFinish}>
            <Form.Item
              name="schoolName"
              label="Okul İsmi"
              rules={[
                {
                  required: true,
                  message: "Okul İsmini boş bırakmayınız",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="username"
              label="Kullanıcı Adı"
              rules={[
                {
                  required: true,
                  message: "Kullanıcı adını boş bırakmayınız",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="password"
              label="Şifre"
              rules={[
                {
                  required: true,
                  message: "Şifreyi boş bırakmayınız",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Form>
        </Modal>
      </Card>
    </>
  );
};

export default SchoolSettings;

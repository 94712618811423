import React, { useEffect, useState } from "react";
import LoginView from "./LoginView";
import { Form } from "antd";
import axios from "axios";
import { useDispatch } from "react-redux";
import { login } from "../../reducer/app";
import { useHistory } from "react-router-dom";

const Login = (props) => {
  const [isRegistered, setIsRegistered] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [schools, setSchools] = useState([]);
  const [classLevel, setClassLevel] = useState([]);
  const [schoolSelectable, setSchoolSelectable] = useState(false);
  const history = useHistory();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    setIsLoading(true);
    axios
      .get("/api/common/loadIndex")
      .then((response) => {
        setSchoolSelectable(response.data.data.schoolSelectable === 1);
        const schoolList = response.data.data.schools.map((val) => ({
          value: val.id,
          label: val.schoolName,
        }));
        setSchools([{ value: 0, label: "Diğer" }, ...schoolList]);
        const classLevel = response.data.data.classLevel.map((val) => ({
          value: val.id,
          label: val.description,
        }));
        setClassLevel(classLevel);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const onFinish = (values) => {
    const url = isRegistered ? "/api/auth/login" : "/api/auth/register";

    setIsLoading(true);
    axios
      .post(url, values)
      .then((response) => {
        if (!response.data?.data?.user) {
          setIsRegistered(false);
        }

        if (response.data?.data?.user) {
          dispatch(login({ user: response.data.data.user }));
          history.push("/ana-sayfa");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <LoginView
      form={form}
      onFinish={onFinish}
      isRegistered={isRegistered}
      isLoading={isLoading}
      schools={schools}
      classLevel={classLevel}
      schoolSelectable={schoolSelectable}
    />
  );
};

export default Login;

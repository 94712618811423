import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Input,
  Row,
  Select,
  Space,
  Switch,
  Table,
  Typography,
} from "antd";
import axios from "axios";

const { Option } = Select;

const classLevelPermissionColumns = (onChangeClosed) => [
  {
    title: "Test Adı",
    dataIndex: "testName",
    key: "testName",
  },
  {
    title: "Sınav durumu",
    dataIndex: "closed",
    key: "closed",
    render: (closed, data) => (
      <Space>
        <Switch
          checked={closed === 0}
          onChange={(value) => onChangeClosed(data.id, value)}
        />
        <Typography.Text>{closed === 1 ? "KAPALI" : "AÇIK"}</Typography.Text>
      </Space>
    ),
  },
];

const TestConfig = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [canShowResult, setCanShowResult] = useState(false);
  const [canShowResultInfo, setCanShowResultInfo] = useState("");
  const [testList, setTestList] = useState([]);
  const [classLevels, setClassLevels] = useState([]);
  const [selectedClassLevel, setSelectedClassLevel] = useState(-1);
  const [schoolSelectable, setSchoolSelectable] = useState(false);

  const onClickSave = () => {
    setIsLoading(true);
    axios
      .post("/api/admin/setTestConfig", {
        canShowResult,
        canShowResultInfo,
        schoolSelectable,
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onClickSaveTestPermission = () => {
    setIsLoading(true);
    axios
      .post("/api/admin/setTestClosed", {
        classLevel: selectedClassLevel,
        testList,
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onChangeClosed = (testId, value) => {
    setTestList(
      testList.map((test) => {
        if (test.id === testId) {
          return { ...test, closed: value ? 0 : 1 };
        }
        return test;
      })
    );
  };

  useEffect(() => {
    if (selectedClassLevel !== -1) {
      setIsLoading(true);
      axios
        .post("/api/admin/closedTestList", { classLevel: selectedClassLevel })
        .then((response) => {
          if (response.data.success) {
            setTestList(response.data.data);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [selectedClassLevel]);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get("/api/admin/testConfig")
      .then((response) => {
        if (response.data.success) {
          setCanShowResult(response.data.data.config.canShowResult === 1);
          setCanShowResultInfo(response.data.data.config.canShowResultInfo);
          setSchoolSelectable(response.data.data.config.schoolSelectable === 1);
          setTestList(response.data.data.testList);
          setClassLevels(response.data.data.classLevels);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Card title="Genel Ayarlar">
          <div>
            <Typography.Text style={{ display: "block" }}>
              Sonuç metnini giriniz
            </Typography.Text>
            <Input
              type="text"
              style={{ width: 400, marginBottom: 8 }}
              value={canShowResultInfo}
              onChange={(e) => setCanShowResultInfo(e.target.value)}
            />
          </div>
          <div style={{ marginTop: 12, marginBottom: 12 }}>
            <Space>
              <Typography.Text>Öğrenci sonuçları görebilir mi ?</Typography.Text>
              <Switch checked={canShowResult} onChange={setCanShowResult} />
            </Space>
          </div>
          <div style={{ marginTop: 12, marginBottom: 12 }}>
            <Space>
              <Typography.Text>
                Kayıt ekranından okul seçimi olsun mu ?
              </Typography.Text>
              <Switch
                checked={schoolSelectable}
                onChange={setSchoolSelectable}
              />
            </Space>
          </div>
          <Button
            type="primary"
            onClick={onClickSave}
            loading={isLoading}
            disabled={isLoading}
          >
            Kaydet
          </Button>
        </Card>
      </Col>
      <Col span={24}>
        <Card title="Sınıf seviyesi sınav kapatma">
          <Select
            value={selectedClassLevel}
            style={{ width: 400, marginBottom: 8 }}
            onChange={setSelectedClassLevel}
          >
            <Option value={-1}>Sınıf seçiniz</Option>
            {classLevels.map((classLevel) => (
              <Option key={classLevel.id} value={classLevel.id}>
                {classLevel.description}
              </Option>
            ))}
          </Select>

          <Table
            columns={classLevelPermissionColumns(onChangeClosed)}
            dataSource={testList}
            bordered
            rowKey="id"
            pagination={false}
          />

          <Button
            type="primary"
            onClick={onClickSaveTestPermission}
            loading={isLoading}
            disabled={isLoading}
          >
            Kaydet
          </Button>
        </Card>
      </Col>
    </Row>
  );
};

export default TestConfig;

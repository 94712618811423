import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import "antd/dist/reset.css";
import "./assets/style/antdextra.css";
import "./assets/style/App.css";
import { Provider } from "react-redux";
import store from "./store";
import setupAxios from "./util/setupAxios";
import { App } from "./pages";

setupAxios(store);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

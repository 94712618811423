export function getFilterValues(arr, field) {
  const unique = arr
    .map((item) => item[field])
    .reduce((acc, current) => {
      if (!acc.includes(current)) {
        acc.push(current);
      }
      return acc;
    }, [])
    .sort((a, b) => a.localeCompare(b)); // Sort using localeCompare

  return unique.map((value) => ({ text: value, value: value }));
}

export function generateStringFilterFunc(field) {
  return (value, record) => record[field].indexOf(value) === 0;
}

export async function handleDownload(url, data, filename, onFinish) {
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // Add any additional headers if required
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      onFinish();
      throw new Error("Network response was not ok");
    }

    const blob = await response.blob();

    // Create a link element for the download
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = filename;

    // Append to the body, click, and then remove it
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    onFinish();
  } catch (error) {
    onFinish();
    console.error("Download failed:", error);
  }
}

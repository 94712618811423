import StudentList from "./components/StudentList";
import TestReports from "./components/TestReports";
import TestConfig from "./components/TestConfig";
import SchoolSettings from "./components/SchoolSettings";
import {
  BankOutlined,
  PieChartOutlined,
  SettingOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import React from "react";

const ADMIN_CONFIG = [
  {
    key: 1,
    component: <StudentList />,
    label: "Öğrenci Listesi",
    icon: <TeamOutlined />,
  },
  {
    key: 2,
    component: <TestReports />,
    label: "Test Raporları",
    icon: <PieChartOutlined />,
  },
  {
    key: 3,
    component: <TestConfig />,
    label: "Test Ayarları",
    icon: <SettingOutlined />,
  },
  {
    key: 4,
    component: <SchoolSettings />,
    label: "Okul İşlemleri",
    icon: <BankOutlined />,
  },
];

const SCHOOL_CONFIG = [
  {
    key: 1,
    component: <StudentList />,
    label: "Öğrenci Listesi",
    icon: <TeamOutlined />,
  },
  {
    key: 2,
    component: <TestReports />,
    label: "Test Raporları",
    icon: <PieChartOutlined />,
  },
];

export { ADMIN_CONFIG, SCHOOL_CONFIG };

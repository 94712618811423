import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  list: [],
};

export const testSlice = createSlice({
  name: "test",
  initialState,
  reducers: {
    setTestList: (state, action) => {
      state.list = action.payload;
    },
  },
});

export const { setTestList } = testSlice.actions;

export default testSlice.reducer;

import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import TestView from "./TestView";
import axios from "axios";
import message from "antd/lib/message";

const Test = (props) => {
  let location = useLocation();
  const [questions, setQuestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showHasNoQuestionModal, setShowHasNoQuestionModal] = useState(false);
  const [isUserSolveBefore, setIsUserSolveBefore] = useState(false);
  const [testConfig, setTestConfig] = useState(false);
  const [isSavingResult, setIsSavingResult] = useState(false);
  const [showResultModal, setShowResultModal] = useState(false);

  const testId = location.state && location.state.id;
  const history = useHistory();

  const onSubmitAnswers = (data) => {
    if (data.find((val) => val.value === null)) {
      message.info("Tüm soruları cevaplayınız.");
    } else {
      const answers = data.map((val) => val.value).join("");
      setIsSavingResult(true);
      axios
        .post("/api/test/saveAnswers", { answers, testId })
        .then((response) => {
          if (response.data.success) {
            setShowResultModal(true);
          }
        })
        .finally(() => {
          setIsLoading(false);
          setIsSavingResult(false);
        });
    }
  };

  const solveAgainClick = () => {
    setIsUserSolveBefore(false);
  };

  const showResultClick = () => {
    if (testConfig?.canShowResult === 0) {
      message.info(
        testConfig.canShowResultInfo === ""
          ? "Test sonucu yönetici tarafından kapatılmış."
          : testConfig.canShowResultInfo
      );
    } else {
      axios.get(`/api/test/getReportLink?testId=${testId}`).then((response) => {
        if (response.data.success) {
          window.open(response.data.result, "_blank");
        }
      });
    }
  };

  const showResultModalClick = () => {
    if (testConfig?.canShowResult === 0) {
      message.info(
        testConfig.canShowResultInfo === ""
          ? "Test sonucu yönetici tarafından kapatılmış."
          : testConfig.canShowResultInfo
      );
    } else {
      axios.get(`/api/test/getReportLink?testId=${testId}`).then((response) => {
        if (response.data.success) {
          history.replace("/ana-sayfa");
          window.open(response.data.result, "_blank");
        }
      });
    }
  };

  useEffect(() => {
    setIsLoading(true);
    setQuestions([]);
    axios
      .get("/api/test/questions", { params: { testId } })
      .then((response) => {
        if (response.data.success) {
          if (response.data.data.questions.length === 0) {
            setShowHasNoQuestionModal(true);
          }
          setQuestions(response.data.data.questions);
          setTestConfig(response.data.data.testConfig);
          setIsUserSolveBefore(response.data.data.isUserSolveBefore);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [testId]);

  return (
    <TestView
      solveAgainClick={solveAgainClick}
      title={location.state ? location.state.title : ""}
      questions={questions}
      onSubmitAnswers={onSubmitAnswers}
      isLoading={isLoading}
      showHasNoQuestionModal={showHasNoQuestionModal}
      isUserSolveBefore={isUserSolveBefore}
      showResultClick={showResultClick}
      isSavingResult={isSavingResult}
      showResultModal={showResultModal}
      showResultModalClick={showResultModalClick}
    />
  );
};

export default Test;
